import Layout from '@/components/Layout';
import Link from 'next/link';
import { useEffect, useState } from 'react';

export default function ErrorNotFound() {
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    const header = document.getElementById('pageHeader');
    const headerHeight = header?.clientHeight;

    const footer = document.getElementById('pageFooter');
    const footerHeight = footer?.clientHeight;

    setContentHeight(`calc(100vh - (${headerHeight}px + ${footerHeight}px))`);
  }, []);

  useEffect(() => {
    const header = document.getElementById('pageHeader');
    const headerHeight = header?.clientHeight;

    const footer = document.getElementById('pageFooter');
    const footerHeight = footer?.clientHeight;

    const handleResize = () => {
      setContentHeight(`calc(100vh - (${headerHeight}px + ${footerHeight}px))`);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <Layout>
      <div
        id="notFound-content"
        className="not-found-page"
        style={{ height: contentHeight }}
      >
        <div className="container">
          <div className="wrapper">
            <h2>Oops! Page not found.</h2>
            <h1>404</h1>
            <p>We are sorry but the page you are looking for does not exist.</p>
            <br></br>
            <Link className="linkd" href="/">
              <a>Back to Home</a>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
